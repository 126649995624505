import { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useLocation } from "react-router-dom";
import { IoCloseCircleSharp } from "react-icons/io5";

function MyPlan({ setShowModal }) {
  const [activeTab, setActiveTab] = useState("Professional");
  const location = useLocation();
  const userEmail = location.state?.userEmail;

  const makePayment = async (plan) => {
    // const stripe = await loadStripe(
    //   "pk_test_51P8XIhRwIvlITD2XGrRGp4Mgd0GCVyKzaufbkFQ1BHJEfUxKFw1NF0Yz6PN5sFjxYeIk4ezYIp2eucLb8iAcMqfu00ctt9B36D"
    // );
    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY);
    // const stripe = await loadStripe(stripeK);
    const serverUrl = process.env.REACT_APP_SERVER_URL;

    const body = {
      email: userEmail,
      products: [plan],
    };

    const token = localStorage.getItem("accessToken");

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    try {
      console.log("Sending request to server...");
      const response = await fetch(`${serverUrl}/create-checkout-session`, {
        method: "POST",
        headers,
        body: JSON.stringify(body),
      });
      console.log("Sending request to server2222...");
      const session = await response.json();
      // const result = await stripe.redirectToCheckout({
      //   sessionId: session.id,
      // });
    } catch (error) {
      console.error("Error in makePayment:", error);
    }
  };

  const plans = {
    Basic: [
      {
        name: "Basic",
        price: 4.99,
        apiVersion: "GPT-3.5",
        usage: "1000 tokens",
        description: [
          "✔️ Use advanced Gen AI models, like Claude 3.5 Sonnet, GPT4o, Gemini 1.5 Pro, and others",
        ],
      },
      {
        name: "Standard",
        price: 9.99,
        usage: "2000 tokens",
        description: [
          "✔️ Use advanced Gen AI models, like Claude 3.5 Sonnet, GPT4o, Gemini 1.5 Pro, and others",
          "✔️ Enjoy higher monthly message limits",
        ],
      },
      {
        name: "Premium",
        price: 19.99,
        usage: "3000 tokens",
        description: [
          "✔️ Use advanced Gen AI models, like Claude 3.5 Sonnet, GPT4o, Gemini 1.5 Pro, and others",
          "✔️ Enjoy higher monthly message limits",
        ],
      },
    ],
    Professional: [
      {
        name: "BasicQ",
        price: 11.99,
        apiVersion: "GPT-3.5",
        usage: "12000 tokens",
        description: [
          "✔️ Use advanced Gen AI models, like Claude 3.5 Sonnet, GPT4o, Gemini 1.5 Pro, and others",
          "✔️ Enjoy higher monthly message limits",
        ],
      }, // 2 months free
      {
        name: "StandardQ",
        price: 24.99,
        apiVersion: "GPT-4-turbo",
        usage: "24000 tokens",
        description: [
          "✔️ Use advanced Gen AI models, like Claude 3.5 Sonnet, GPT4o, Gemini 1.5 Pro, and others",
          "✔️ Enjoy higher monthly message limits",
        ],
      },
      {
        name: "PerimumQ",
        price: 49.99,
        apiVersion: "Gemini",
        usage: "36000 tokens",
        description: [
          "✔️ Use advanced Gen AI models, like Claude 3.5 Sonnet, GPT4o, Gemini 1.5 Pro, and others",
          "✔️ Enjoy higher monthly message limits",
        ],
      },
    ],
  };

  return (
    // <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4">
    //   <div className="bg-white rounded-lg shadow-xl overflow-hidden max-w-4xl w-full h-[500px] flex flex-col relative">
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 dark:bg-gray-900 dark:bg-opacity-80">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl overflow-hidden max-w-4xl w-full h-[500px] flex flex-col relative">
        <button
          onClick={() => setShowModal(false)}
          className="absolute top-0 right-0 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-white"
        >
          <IoCloseCircleSharp size={24} />
        </button>
        {/* <div className="flex justify-between px-5 pt-5 border-b"> */}
        <div className="flex justify-between px-5 pt-5 border-b dark:border-gray-700">
          <button
            className={`text-lg font-medium ${
              activeTab === "Basic"
                ? "text-blue-600 border-b-2 border-blue-600 dark:text-blue-400 dark:border-blue-400"
                : "text-gray-500 dark:text-gray-400"
            }`}
            onClick={() => setActiveTab("Basic")}
          >
            Basic
          </button>
          <button
            className={`text-lg font-medium ${
              activeTab === "Professional"
                ? "text-blue-600 border-b-2 border-blue-600 dark:text-blue-400 dark:border-blue-400"
                : "text-gray-500 dark:text-gray-400"
            }`}
            onClick={() => setActiveTab("Professional")}
          >
            Professional
          </button>
        </div>

        {/* <div className="flex items-center justify-center flex-1 p-5"> */}
        <div className="flex items-center justify-center flex-1 p-5">
          {activeTab === "Basic" && (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {plans.Basic.map((plan) => (
                <div
                  key={plan.name}
                  className="bg-white dark:bg-gray-700 p-5 rounded-lg shadow-md text-center border border-gray-200 dark:border-gray-600 w-64 flex flex-col h-full"
                >
                  <div className="flex-grow">
                    <h3 className="text-xl font-bold text-gray-900 dark:text-gray-200 pt-5">
                      {plan.name}
                    </h3>
                    <p className="mt-2 text-2xl font-bold text-gray-900 dark:text-gray-200 pt-5">
                      ${plan.price}
                      <span className="text-base font-medium text-gray-500 dark:text-gray-400">
                        {"/mo"}
                      </span>
                    </p>
                    <div className="mt-2 text-left pt-5">
                      {Array.isArray(plan.description) ? (
                        plan.description.map((paragraph, index) => (
                          <p
                            key={index}
                            className="font-small text-gray-700 dark:text-gray-300 mb-2"
                          >
                            {paragraph}
                          </p>
                        ))
                      ) : (
                        <p className="font-small text-gray-700 dark:text-gray-300 mb-2">
                          {plan.description}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="mt-auto pt-5">
                    <button
                      className="w-full bg-blue-500 text-white rounded-md px-4 py-2 hover:bg-blue-700 dark:bg-blue-600 dark:hover:bg-blue-800"
                      onClick={() => makePayment(plan)}
                    >
                      Get Started
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}

          {activeTab === "Professional" && (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {plans.Professional.map((plan) => (
                <div
                  key={plan.name}
                  className="bg-white dark:bg-gray-700 p-5 rounded-lg shadow-md text-center border border-gray-200 dark:border-gray-600 w-64 flex flex-col h-full"
                >
                  <div className="flex-grow">
                    <h3 className="text-xl font-bold text-gray-900 dark:text-gray-200 pt-5">
                      {plan.name}
                    </h3>
                    <p className="mt-2 text-2xl font-bold text-gray-900 dark:text-gray-200 pt-5">
                      ${plan.price}
                      <span className="text-base font-medium text-gray-500 dark:text-gray-400">
                        {"/mo"}
                      </span>
                    </p>
                    <div className="mt-2 text-left pt-5">
                      {Array.isArray(plan.description) ? (
                        plan.description.map((paragraph, index) => (
                          <p
                            key={index}
                            className="font-small text-gray-700 dark:text-gray-300 mb-2"
                          >
                            {paragraph}
                          </p>
                        ))
                      ) : (
                        <p className="font-small text-gray-700 dark:text-gray-300 mb-2">
                          {plan.description}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="mt-auto pt-5">
                    <button
                      className="w-full bg-blue-500 text-white rounded-md px-4 py-2 hover:bg-blue-700 dark:bg-blue-600 dark:hover:bg-blue-800"
                      onClick={() => makePayment(plan)}
                    >
                      Get Started
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default MyPlan;
