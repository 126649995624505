import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import React from "react";
import { ThemeProvider, useTheme } from "./ThemeContext";
import Login from "./Login";
import Signup from "./Signup";
import Dashboard from "./Dashboard";
import MainPage from "./MainPage";
import About from "./About";
import Privacy from "./Privacy";

function AppContent() {
  const { theme } = useTheme();

  return (
    <div className={`min-h-screen ${theme}`}>
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/mainpage" />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/mainpage" element={<MainPage />} />
          <Route path="/about" element={<About />} />
          <Route path="/privacy" element={<Privacy />} />
        </Routes>
      </Router>
    </div>
  );
}

function App() {
  return (
    <ThemeProvider>
      <AppContent />
    </ThemeProvider>
  );
}

export default App;
