import React from "react";
import { Link } from "react-router-dom";

const Navigation = ({ handleModelChange }) => {
  return (
    <nav className="navbar flex flex-col space-y-2 p-4 bg-white rounded-lg shadow-md">
      <Link
        onClick={() => handleModelChange("GPT 4o mini")}
        className="flex justify-center items-center text-emerald-500 hover:text-emerald-600"
      >
        GPT 4o mini
      </Link>
      <Link
        onClick={() => handleModelChange("GPT 4o")}
        className="flex justify-center items-center text-emerald-500 hover:text-emerald-600"
      >
        GPT 4o
      </Link>
      {/* <Link
        onClick={() => handleModelChange("GPT o1 preview")}
        className="flex justify-center items-center text-emerald-500 hover:text-emerald-600"
      >
        OpenAI o1 preview
      </Link> */}
      <Link
        onClick={() => handleModelChange("GPT o1 mini")}
        className="flex justify-center items-center text-emerald-500 hover:text-emerald-600"
      >
        OpenAI o1 mini
      </Link>
      <Link
        onClick={() => handleModelChange("Claude 3 haiku")}
        className="flex justify-center items-center text-yellow-700 hover:text-yellow-900"
      >
        Claude 3 haiku
      </Link>
      <Link
        onClick={() => handleModelChange("Claude 3.5 sonnet")}
        className="flex justify-center items-center text-yellow-700 hover:text-yellow-900"
      >
        Claude 3.5 sonnet
      </Link>
      {/* <Link
        onClick={() => handleModelChange("Claude 3 opus")}
        className="flex justify-center items-center text-yellow-700 hover:text-yellow-900"
      >
        Claude 3 opus
      </Link> */}
      <Link
        onClick={() => handleModelChange("Gemini 1.5 Pro")}
        className="flex justify-center items-center text-blue-400 hover:text-blue-700"
      >
        Gemini 1.5 Pro
      </Link>
      <Link
        onClick={() => handleModelChange("Gemini 1.5 Flash")}
        className="flex justify-center items-center text-blue-400 hover:text-blue-700"
      >
        Gemini 1.5 Flash
      </Link>
      <Link
        onClick={() => handleModelChange("Claude 3 haiku")}
        className="flex justify-center items-center text-black hover:text-black"
      >
        Machine Learning
      </Link>
      <Link
        onClick={() => handleModelChange("Claude 3 haiku")}
        className="flex justify-center items-center text-black hover:text-black"
      >
        Programming
      </Link>
    </nav>
  );
};

export default Navigation;
