import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation, Link } from "react-router-dom";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showForgetPassword, setShowForgetPassword] = useState(false);
  const [emailToReset, setEmailToReset] = useState("");
  const [verificationMessage, setVerificationMessage] = useState(""); // New state for the verification message
  const navigate = useNavigate(); // Create navigate function
  const location = useLocation();
  axios.defaults.withCredentials = true;
  const serverUrl = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    if (location.state?.verificationMessage) {
      setVerificationMessage(location.state.verificationMessage);
    }
  }, [location.state]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`${serverUrl}/api/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Login successful:", data);
        localStorage.setItem("userId", data.userId);
        localStorage.setItem("accessToken", data.accessToken);
        localStorage.setItem("refreshToken", data.refreshToken);
        document.cookie = `accessToken=${data.accessToken}; path=/; secure; httponly;`;
        navigate("/mainpage");
      } else {
        const errorData = await response.json();
        alert(errorData.message);
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.message);
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  const handleForgotPassword = async () => {
    try {
      const response = await axios.post("/forget-password", {
        email: emailToReset,
      });
      alert(response.data.message);
      setShowForgetPassword(false);
      // const response = await axios.post("/createCheckoutSession");
      // alert(response.data);
    } catch (error) {
      alert("Error sending reset email");
    }
  };

  return (
    <div className="flex justify-center items-center bg-blue-100 h-screen">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-sm h-100">
        <h2 className="text-2xl font-bold mb-6 text-center text-gray-800">
          Login
        </h2>
        {verificationMessage && (
          <div className="text-green-500 mb-4 text-center">
            {verificationMessage}
          </div>
        )}
        <div className="logincontainer">
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <input
                placeholder="*Email"
                className="border-2 border-gray-300 p-3 w-full rounded-md focus:border-purple-500"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <input
                className="border-2 border-gray-300 p-3 w-full rounded-md focus:border-purple-500"
                placeholder="*Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button
              type="submit"
              className="w-full bg-purple-600 text-white p-3 rounded-md hover:bg-purple-700 focus:outline-none"
            >
              Continue
            </button>
          </form>
          <p className="mt-4 text-center">
            Don't have an account?
            <Link to="/signup" className="text-purple-600 hover:underline">
              {" "}
              Sign up
            </Link>
          </p>
          <p className="mt-4 text-center">
            Forgot Password?
            <Link
              to="#"
              onClick={() => setShowForgetPassword(true)}
              className="text-purple-600 hover:underline"
            >
              {" "}
              Forgot Password
            </Link>
          </p>
          {showForgetPassword && (
            <div className="mt-4">
              <input
                type="email"
                placeholder="Enter your email"
                className="border-2 border-gray-300 p-3 w-full rounded-md focus:border-purple-500"
                value={emailToReset}
                onChange={(e) => setEmailToReset(e.target.value)}
              />
              <button
                onClick={handleForgotPassword}
                className="w-full bg-purple-600 text-white p-3 rounded-md hover:bg-purple-700 focus:outline-none mt-4"
              >
                Send Reset Email
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Login;
